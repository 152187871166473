import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { effects, reducers, sharedFeatureKey } from ".";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(sharedFeatureKey, reducers),
    EffectsModule.forFeature(effects),
  ],
})
export class SharedStoreModule {}
